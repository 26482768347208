@use '../vars';
@use '../mixins';

* {
  margin: 0;
  box-sizing: border-box;
  font-family: vars.$font;
  font-weight: vars.$normal-font-weight;
}

html {
  background-color: vars.$background-color;
}

body {
  font-size: vars.$medium-font-size;

  #root > header {
    margin: vars.$large-padding auto 0 auto;

    a {
      text-align: center;
    }

    @include mixins.center;

    img {
      max-width: min(90%, 600px);
    }

    h1 {
      display: inline-block;
      text-align: center;
    }
  }

  .info {
    @include mixins.card;
    margin: vars.$large-padding vars.$large-padding 0 vars.$large-padding;

    p {
      opacity: 0.8;
      font-size: vars.$medium-font-size;

      &:not(:first-child) {
        margin-top: vars.$medium-padding;
      }
    }
  }

  main {
    .environment {
      position: absolute;
      transform-origin: center center;
      transform: translateX(calc(50% - 1ch - #{vars.$medium-padding})) rotate(-90deg);
      right: 0;
      top: 50%;
      font-size: 40px;
      text-transform: uppercase;
      opacity: 0.07;
      z-index: -1;
    }
  }
}

h3,
h4 {
  font-weight: vars.$bold-font-weight;
  color: #555;
  margin-top: vars.$medium-padding;
  margin-bottom: vars.$small-padding;
}

a {
  @include mixins.link-style;
}
