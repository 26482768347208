@use 'vars';

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin profile-picture($size) {
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  @include square($size);
}

@mixin inner-spacing($spacing, $horizontal: false) {
  & > *:not(:last-child) {
    @if $horizontal {
      margin-right: $spacing;
    } @else {
      margin-bottom: $spacing;
    }
  }
}

@mixin button {
  color: #14c9c0;
  border: none;
  cursor: pointer;
  background: none;
  font-size: vars.$small-font-size;
  padding: 0;

  transition: transform vars.$short-animation-time ease-in-out;

  &:not(:disabled):hover {
    transform: translateY(-3px);
  }

  &:disabled {
    opacity: 0.7;
  }
}

@mixin background-blur($radius) {
  @supports (backdrop-filter: blur($radius)) {
    backdrop-filter: blur($radius);
    background: rgba(white, 0.5);
  }

  @supports not (backdrop-filter: blur($radius)) {
    background: white;
  }
}

@mixin card {
  padding: vars.$medium-padding vars.$large-padding;
  border-radius: vars.$border-radius;
  box-shadow: vars.$shadow;
  background: white;
}

@mixin hover-grow($size: 1.1) {
  transition: transform vars.$short-animation-time;

  &:not(:disabled):hover {
    transform: scale($size);
  }
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin absolute-center-self {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin large-button {
  display: block;
  background-color: vars.$accent-color;
  color: vars.$main-color;
  padding: vars.$small-padding 0;
  text-align: center;
  font-size: vars.$medium-font-size;
  margin: 0 auto;
  border-radius: 1000px;
  border: none;
  width: 100px;
  outline: none;
  cursor: pointer;

  @include hover-grow;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

@mixin scroll-bar {
  @media (hover: hover) {
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: vars.$accent-color;
      border-radius: 4px;
    }
  }
}

@mixin link-style {
  color: vars.$accent-color;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: transform vars.$short-animation-time, text-shadow vars.$short-animation-time;

  &:hover {
    transform: scale(1.03);

    text-shadow: -1px 0 0 rgba(vars.$accent-color, 0.5),
      -2px 0 1px rgba(vars.$accent-color, 0.3), -3px 0 2px rgba(vars.$accent-color, 0.1);
  }
}
