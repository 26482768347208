$main-color: #000000;
$accent-color: #47c2d0;
$error-color: #a30808;
$background-color: #f6f6f6;

$small-padding: 10px;
$medium-padding: 20px;
$large-padding: 50px;

$border-radius: 10px;

$very-small-font-size: 12px;
$small-font-size: 16px;
$medium-font-size: 18px;
$large-font-size: 24px;
$medium-large-font-size: 30px;
$very-large-font-size: 40px;

$max-site-width: 1920px;

$shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);

$long-animation-time: 300ms;
$short-animation-time: 200ms;

$font: 'Roboto', sans-serif;
$bold-font-weight: 700;
$normal-font-weight: 400;
$light-font-weight: 100;
