@use '../../vars';
@use '../../mixins';

#full-page-loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  flex-direction: column;
  z-index: 10000;
  @include mixins.center;
  @include mixins.background-blur(10px);

  p {
    margin-top: vars.$large-padding;
  }
}
