@use '../../vars';
@use '../../mixins';

.modal {
  @include mixins.card;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 300px;

  &:focus {
    outline: none;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: vars.$medium-padding;

    h2 {
      color: vars.$accent-color;
    }

    .exit {
      @include mixins.square(20px);
      cursor: pointer;
      @include mixins.hover-grow;
      box-sizing: content-box;
    }
  }

  p {
    font-weight: vars.$light-font-weight;
    font-size: vars.$small-font-size;
    word-break: break-word;
  }

  button {
    @include mixins.large-button;
    margin-top: vars.$medium-padding;
    width: auto;
    padding: vars.$small-padding vars.$medium-padding;
  }
}
