@use '../../vars';
@use '../../mixins';

#analyser {
  padding: 0 vars.$large-padding;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-bottom: 2 * vars.$large-padding;

  h2 {
    margin: vars.$large-padding 0 vars.$medium-padding 0;
  }

  .upload-area {
    #drop-area {
      border-radius: vars.$border-radius;
      border: 5px dashed gray;
      padding: vars.$medium-padding;
      backdrop-filter: blur(4px);

      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-height: 200px;
      &.active {
        background: lightgray;
      }

      #files {
        margin-top: vars.$medium-padding;
        overflow-y: auto;

        .file {
          display: flex;
          align-items: center;
          overflow: hidden;

          p {
            font-family: monospace;
            font-size: vars.$small-font-size;
          }

          img {
            @include mixins.square(24px + vars.$small-padding);
            padding: vars.$small-padding;
            @include mixins.hover-grow(1.3);
          }
        }
      }
    }

    button {
      @include mixins.large-button();
      margin-top: vars.$medium-padding;
    }
  }

  .count {
    P {
      font-family: monospace;
      color: vars.$accent-color;
      padding-bottom: vars.$medium-padding;

      img {
        @include mixins.square(1.5ch);
      }
    }
  }

  .sliders {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div {
      width: 400px;
    }
  }

  .terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: vars.$medium-padding;

    .term {
      border-radius: vars.$border-radius;
      border: 3px solid vars.$accent-color;

      &.hidden {
        opacity: 0.25;

        .synonyms {
          pointer-events: none;
        }
      }

      &.negative {
        border-color: vars.$error-color;
      }

      transition: border-color vars.$short-animation-time,
        opacity vars.$short-animation-time;

      padding: vars.$medium-padding;
      margin-right: vars.$medium-padding;
      margin-bottom: vars.$medium-padding;

      cursor: pointer;

      h3 {
        margin: 0;
      }

      .synonyms {
        display: flex;
        flex-wrap: wrap;

        span {
          background: vars.$accent-color;
          padding: vars.$small-padding;
          margin: vars.$small-padding vars.$small-padding 0 0;
          border-radius: vars.$border-radius;
          transition: opacity vars.$short-animation-time;

          &.hidden {
            opacity: 0.4;
          }
          &.negative {
            background: vars.$error-color;
          }

          @include mixins.hover-grow(1.05);
        }
      }
    }
  }

  .export {
    border-radius: vars.$border-radius;
    border: 3px solid vars.$accent-color;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      flex: 1;
      width: 100%;
      min-width: 250px;
      padding: vars.$medium-padding;

      h3 {
        margin-top: 0;
      }

      a {
        word-break: break-all;
        font-family: monospace;
      }
    }
  }
}
